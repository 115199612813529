<template>
  <div class="TextInput relative">
    <input
      v-bind="attr"
      ref="inputField"
      v-model="value"
      :type="inputType"
      class="input"
      :autocomplete="autocomplete"
      :disabled="disabled"
      :class="{
        'cursor-not-allowed': disabled,
        'pl-[36px]': isSearch,
      }"
      @focus="focused = true; $emit('focus')"
      @blur="focused = false; $emit('blur')"
      @keydown.enter="$emit('enter')"
    >
    <div
      class="absolute top-20
      type-xs-medium leading-single uppercase
      pointer-events-none select-none
      transition-all"
      :class="{
        'top-8 type-xxs-medium': focused || (typeof value === 'number' ? true : value?.trim() !== ''),
        'text-brightRed': showErrorCombined,
        'text-darker': !showErrorCombined,
        'left-0': !isSearch,
        'left-[36px]': isSearch,
      }"
    >
      {{ showLabel }}
    </div>
    <div
      v-if="isSearch"
      class="absolute top-16"
    >
      <img
        src="/icons/search.svg"
        class="h-20 w-20"
      >
    </div>

    <div
      v-if="type === 'password'"
      class="absolute top-[13px] right-0 cursor-pointer"
      :class="{
        'right-28': showErrorCombined || showSuccess
      }"
      @click="showPassword = !showPassword"
    >
      <img
        :src="showPassword ? '/icons/eye-closed.svg' :'/icons/eye-open.svg'"
        class="w-24 h-24 relative"
        alt=""
      >
    </div>

    <div v-if="showErrorCombined" class="absolute top-12 right-0 pointer-events-none select-none">
      <img
        src="/icons/form-error.svg"
        class="w-24 h-24"
        alt=""
      >
    </div>

    <div v-else-if="showSuccess" class="absolute top-12 right-0 pointer-events-none select-none">
      <img
        src="/icons/form-ok.svg"
        class="w-24 h-24"
        alt=""
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
const uiStore = useUiStore();

const value = defineModel<string|number>();

const props = withDefaults(defineProps<{
  label: string;
  showError?: boolean;
  showSuccess?: boolean;
  errors?: string[];
  type?: string;
  autocomplete?: string;
  serverName?: string;
  disabled?: boolean;
  isSearch?: boolean;
  focus?: boolean;
  // adds attributes to input field, example :attr="{min: '0', max: '100'}"
  attr?: {
    [key: string]: string;
  };
}>(), {
  title: '',
  showError: false,
  showSuccess: false,
  errors: () => [],
  type: 'text',
  autocomplete: '',
  serverName: '',
  disabled: false,
  isSearch: false,
  attr: () => {},
});

defineEmits<{
  (e: 'focus'): void,
  (e: 'blur'): void,
  (e: 'enter'): void,
}>();

const focused = ref(false);
const showPassword = ref(false);

const inputType = computed(()=> {
  if (props.type === 'password' && showPassword.value) {
    return 'text';
  }
  return props.type;
});

const showErrorCombined = computed(()=> {
  return props.showError || (uiStore.formErrorMsg?.errors && uiStore.formErrorMsg?.errors[props.serverName]);
});

const showLabel = computed(()=> {
  let output = props.label;

  // Server error
  if (uiStore.formErrorMsg?.errors && uiStore.formErrorMsg?.errors[props.serverName]) {
    output += ` - ${uiStore.formErrorMsg.errors[props.serverName][0]}`;
  }
  else if (props.showError && props.errors.length) {
    output += ` - ${props.errors[0]}`;
  }
  return output;
});

const inputField = ref<HTMLElement>();
onMounted(()=> {
  if (props.focus) {
    setTimeout(()=> inputField.value?.focus(), 400);
  }
});

</script>

<style scoped lang="postcss">
</style>
